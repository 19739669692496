import {
  ref, reactive, getCurrentInstance, onMounted, watch
} from 'vue'
import { priceUseCase, getAllItemsKategoriBarang } from '@/domain/usecase'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import moment from 'moment'
import { useRoute } from 'vue-router'
import MultiselectAsync from '@/views/shared-components/select/MultiselectAsync.vue'

export default {
  components: {
    MultiselectAsync
  },
  props: ['fromId', 'dataEdit', 'onCopy', 'onClone'],
  emits: ['reloadData', 'closeSidebar'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const id = ref('')
    const title = ref('')
    const dataForm = reactive({
      code: '',
      version: '',
      dateApply: '',
      endDate: '',
      isActive: false
    })
    const itemOptions = ref([])
    const itemId = ref([])
    const submitted = ref(false)
    const {
      dataEdit,
      onCopy,
      onClone,
      fromId
    } = reactive(props)
    const route = useRoute()
    const rules = {
      code: {
        required: helpers.withMessage('Code harga harus diisi', required)
      },
      version: {
        required: helpers.withMessage('Versi revisi harga harus diisi', required)
      },
      dateApply: {
        required: helpers.withMessage('Tanggal berlaku harga harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const getAllItems = async () => {
      store.dispatch('showLoading')
      const response = await getAllItemsKategoriBarang({
        filter: 'IsActive eq true'
      })
      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil kategori barang, silahkan coba kembali', group: 'bc', life: 3000
        })
      } else {
        itemOptions.value = response.result.map((x: any) => ({
          value: x.Id,
          label: x.Nama
        }))
      }
      store.dispatch('hideLoading')
    }

    const getDataPrice = async (id: any) => {
      const response = await priceUseCase.getSinglePrice(id)
      console.log('res', response)
      return response.result
    }

    const definitionDataForm = async (val: any) => {
      if (onCopy) {
        title.value = 'Duplikat Harga'
        id.value = val.Id
        dataForm.code = `${val.Code}_COPY`
        dataForm.version = val.Version
        dataForm.dateApply = moment(val.EffectiveDate).format('DD-MM-YYYY')
        dataForm.isActive = true
        itemId.value = []
      } else if (onClone) {
        title.value = 'Clone Harga'
        id.value = val.Id
        dataForm.code = `${val.Code}`
        dataForm.version = val.Version
        dataForm.dateApply = moment(val.EffectiveDate).format('DD-MM-YYYY')
        dataForm.isActive = true
        itemId.value = []
      } else if (fromId) {
        title.value = 'Perubahan Harga'
      } else if (val) {
        const data: any = await getDataPrice(val.Id)
        console.log('data', data)
        title.value = 'Edit Harga'
        id.value = data.Id
        dataForm.code = data.Code
        dataForm.version = data.Version
        dataForm.dateApply = moment(data.EffectiveDate).format('DD-MM-YYYY')
        dataForm.isActive = data.IsActive
        itemId.value = data.GoodCategories.map((x: any) => (
          {
            ...x, Id: x.GoodCategoryId, label: x.GoodCategoryName, value: x.GoodCategoryId
          }
        ))
        // getAllItems()
      } else {
        title.value = 'Tambah Harga'
        id.value = ''
        dataForm.code = ''
        dataForm.version = ''
        dataForm.dateApply = ''
        dataForm.isActive = true
        itemId.value = []
        // getAllItems()
      }
      console.log('itemId', itemId.value)
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const dateConvert = (valDate: any, dateEnd: any) => {
      let date
      if (valDate.toString().includes('-')) {
        const arrDate = valDate.split('-')
        date = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T00:00:00+07:00`
      } else if (dateEnd === 1) {
        date = `${moment(valDate).add(1, 'd').format('YYYY-MM-DD')}T00:00:00+07:00`
      } else {
        date = `${moment(valDate).format('YYYY-MM-DD')}T00:00:00+07:00`
      }
      return date
    }

    const submitProccess = async () => {
      const data = {
        Code: dataForm.code.toUpperCase(),
        Version: dataForm.version,
        EffectiveDate: dataForm.dateApply ? dateConvert(dataForm.dateApply, 0) : null,
        EndDate: dataForm.endDate ? dateConvert(dataForm.endDate, 1) : null,
        // IsActive: dataForm.isActive,
        GoodCategoryIds: itemId.value.map((data: any) => data.Id)
      }
      store.dispatch('showLoading')
      const response = await priceUseCase.submitData({
        onCopy,
        id: id.value,
        onClone,
        fromId
      }, data)
      console.log('res', response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
      store.dispatch('hideLoading')
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Harga',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await priceUseCase.deleteData(id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      // console.log('dataEdit', dataEdit)
      definitionDataForm(dataEdit)
    })

    return {
      title,
      dataForm,
      id,
      v$,
      submitted,
      itemOptions,
      itemId,
      onCopy,
      route,
      deleteData,
      submitData,
      closeSidebar,
      onClone
    }
  }
}
