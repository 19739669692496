import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")

  return (_openBlock(), _createBlock(_component_Multiselect, {
    id: _ctx.idInput,
    class: "select-async",
    placeholder: _ctx.placeholder,
    options: _ctx.options,
    "can-clear": _ctx.canClear,
    searchable: _ctx.searchable,
    "close-on-select": _ctx.closeOnSelect,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
    mode: _ctx.mode,
    object: true,
    loading: _ctx.isLoading,
    noOptionsText: _ctx.noOptionsText,
    noResultsText: _ctx.noResultsText,
    label: _ctx.labelDisplay,
    valueProp: _ctx.valueProp,
    disabled: _ctx.disabled,
    onOpen: _ctx.onOpen,
    onChange: _ctx.onChange,
    canDeselect: _ctx.canDeselect,
    strict: false,
    trackBy: _ctx.trackBy,
    minChars: _ctx.minChars,
    filterResults: _ctx.filterResults,
    delay: 500,
    onClose: _ctx.onClose,
    ref: "refMultiselect",
    resolveOnLoad: false
  }, _createSlots({ _: 2 }, [
    (_ctx.$slots['singlelabel'])
      ? {
          name: "singlelabel",
          fn: _withCtx(({ value }) => [
            _renderSlot(_ctx.$slots, "singlelabel", { value })
          ])
        }
      : undefined,
    (_ctx.$slots['option'])
      ? {
          name: "option",
          fn: _withCtx(({ option }) => [
            _renderSlot(_ctx.$slots, "option", { option })
          ])
        }
      : undefined
  ]), 1032, ["id", "placeholder", "options", "can-clear", "searchable", "close-on-select", "modelValue", "mode", "loading", "noOptionsText", "noResultsText", "label", "valueProp", "disabled", "onOpen", "onChange", "canDeselect", "trackBy", "minChars", "filterResults", "onClose"]))
}